import { useEffect, useState } from "react";
import { marked } from "marked";

function App() {
  const chapterTitles = [
    "Paulus groet u",
    "Paulus als mens.",
    "Paulus’ beperkingen.",
    "Paulus gezien door zijn tijdgenoten",
    "Paulus’ oprechtheid",
    "Paulus’ gezondheid",
    "Paulus’ zwakte",
    "Paulus’ kracht",
    "Paulus’ trots",
    "Paulus’ nederigheid",
    "Paulus’ heftigheid",
    "Paulus’ geduld",
    "Paulus’ moed",
    "Paulus’ hoffelijkheid",
    "Paulus’ verontwaardiging",
    "Paulus’ tederheid",
    "Paulus’ bekrompen en zijn ruime blik",
    "Paulus’ medeleven",
    "Paulus’ dankbaarheid",
    "Paulus’ vreugde",
    "Paulus’ geloofszekerheid",
    "Paulus’ hoop en verwachting",
    "Paulus’ liefde",
    "Paulus’ godsvrucht",
    "Paulus’ beminnelijkheid",
    "Paulus’ grootheid",
    "Paulus, een triptiek van zijn karakter",
  ];

  const [myText, setMyText] = useState();
  const [sel, setSel] = useState(0);
  const [mobileMenu, setMobileMenu] = useState(false);

  const fetchData = (chapter) => {
    fetch(`/contents/ch${chapter}.md`)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setMyText(marked.parse(text));
      });
  };

  useEffect(() => {
    let counter = window.localStorage.getItem("sel");
    if (counter) setSel(parseInt(counter));
    const url = JSON.parse(JSON.stringify(window.location.href));
    if (url.indexOf("hfst-") !== -1) {
      const ch = parseInt(url.substring(url.indexOf("hfst-") + 5));
      if (ch <= 26 && ch >= 0) setSel(ch);
    }
  }, []);

  useEffect(() => {
    fetchData(sel);
    window.scrollTo(0, 0);
    window.localStorage.setItem("sel", sel);
    window.history.pushState(`${sel}`, "", `hfst-${sel}`);
  }, [sel]);

  document.title = chapterTitles[sel] + " - karaktervanpaulus.nl";

  const getMenu = () => {
    return (
      <div className="container-fluid">
        <div className="container-fluid btn-group-vertical p-0">
          {chapterTitles.map((x, i) => (
            <button
              className={
                "btn text-left pr-4 pb-2 pt-0 my-0" +
                (sel === i
                  ? " font-weight-bold shadow-lg btn-secondary"
                  : "btn-light")
              }
              onClick={() => {
                setSel(i);
                setMobileMenu(false);
              }}
              key={i}
            >
              <small>Hoofdstuk {i}</small> <br /> {x}
            </button>
          ))}
        </div>
        <div className="p-3 rounded mt-2 bg-light text-justify">
          <h5 className="">Auteurspagina over Hugo Karels</h5>
          <span>
            Ik heb een ‘kleurrijk’ verleden als verftechnicus en ben 35 jaar
            werkzaam geweest in de verfindustrie, voornamelijk op het
            laboratorium en processing. Heb veel interesse in theologie en
            bijbelwetenschap, ben een echte bibliofiel, lees veel en graag.
            Boeken over de apostel Paulus en het werk van de Heilige Geest
            (Charismatische lectuur) hebben mijn bijzondere belangstelling.
          </span>
          <img
            className="rounded mx-auto d-block mt-2"
            src="user-avatar-pic.jpg"
            alt="Foto van auteur"
          ></img>
        </div>
      </div>
    );
  };

  const getNavigation = () => {
    return (
      <>
        <div className="row mt-2">
          <div className="col-6 text-left">
            {sel !== 0 && (
              <button
                type="button"
                class="btn btn-block btn-sm btn-dark"
                onClick={() => setSel(sel - 1)}
              >
                Vorig hoofdstuk
              </button>
            )}
          </div>
          <div className="col-6 text-right">
            {" "}
            {sel !== 26 && (
              <button
                type="button"
                class="btn btn-block btn-sm btn-dark"
                onClick={() => setSel(sel + 1)}
              >
                Volgend hoofdstuk
              </button>
            )}
          </div>
        </div>
        <div className="row d-lg-none">
          <div className="col-12 my-2">
            {" "}
            <button
              className="btn btn-block btn-sm btn-dark"
              onClick={() => setMobileMenu(true)}
            >
              Navigeer
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="App">
      <nav className="navbar navbar-expand-lg bg-dark text-white">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <span className="h2">Karakter van Paulus</span>
              <small className="d-none d-md-none d-lg-block">
                Een karakterschets van de apostel
              </small>
            </div>
          </div>
        </div>
      </nav>
      {!mobileMenu ? (
        <div className="container">
          <div className="row">
            <div className="col-4 d-none d-lg-block pt-2">{getMenu()}</div>
            <div className="col-12 col-lg-8">
              <section className="container text-left pb-5 mb-4 px-0">
                {sel === 0 && (
                  <div className="row my-2 border border-dark rounded py-3">
                    <div className="col-lg-7 col-12 pb-1">
                      <span>
                        <h5>Welkom op karaktervanpaulus.nl!</h5>
                        Wie is Paulus? We mogen Paulus bewonderen, waarom niet?
                        Maar we zijn geen navolgers van Paulus, want Christus is
                        onverdeeld. En alleen Hij is voor ons gekruisigd. Want
                        wie is Paulus, anders dan een dienstknecht, een slaaf
                        van Jezus Christus? Paulus is een mens van gelijke
                        hoedanigheden als wij. Wanneer wij ons in hem herkennen,
                        dan is hij dichterbij gekomen door dit boek, en het doel
                        bereikt.
                        <br />
                        <small>
                          Wie is Paulus? Een karakterschets van de apostel
                          (16/05/2012) Overdenking / Preek oorspronkelijk op
                          blocnoot.nl
                        </small>
                      </span>
                    </div>
                    <div className="col-lg-5 col-12">
                      <img
                        src="/Antiochie-in-Pisidie.jpg"
                        className="img-fluid"
                        alt="Antiochie in Pisidie"
                      />
                    </div>
                  </div>
                )}

                {getNavigation()}
                <div>
                  <small>Hoofdstuk {sel}</small>
                  <h2 className="">{chapterTitles[sel]}</h2>
                </div>
                <article
                  dangerouslySetInnerHTML={{ __html: myText ? myText : "" }}
                  className="reading"
                ></article>
                {getNavigation()}
              </section>
            </div>
          </div>
        </div>
      ) : (
        <div className="container my-4">{getMenu()}</div>
      )}
    </div>
  );
}

export default App;
